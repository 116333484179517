import React from "react"
import PropTypes from "prop-types"

class ShowVideo extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.movimientoPuntero = this.movimientoPuntero.bind(this);
    this.state = { x: 0, y: 0 };
  }

  movimientoPuntero(evento) {
    this.setState({
      x: evento.clientX,
      y: evento.clientY
    });
  }

  render() {
    return (
      <div style={{ height: '100vh' }} onMouseMove={this.movimientoPuntero}>
        <h2>{this.props.params["context_title"]}</h2>        
        <p>La posición actual del puntero es: ({this.state.x}, {this.state.y})</p>
        <video width="640" height="360" controls>
          <source src="https://nuslms.s3-eu-west-1.amazonaws.com/CNP1/ClaseCNP.mp4" type="video/mp4"/>
          <source src="https://nuslms.s3-eu-west-1.amazonaws.com/CNP1/claseCPN.ogg" type="video/ogg"/>
        </video>
      </div>
    );
  }}

export default ShowVideo
